var cards = document.querySelectorAll('.nzap-contact-card');

cards.forEach(card => {
    var data = card.getAttribute('data-details');
    var values = atob(data);
    if(values) {
        values.split('&').forEach((value, i) => {
            var pair = value.split('=');
            if(pair.length == 2) {
                var el = card.querySelector('.'+pair[0]) as HTMLElement;
                if(el) {
                    el.append(pair[1]);
                }
            }
        })
    }
})