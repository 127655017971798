(function() {
    var link = document.querySelector('.cs-edit-page-link');
    if(link && !localStorage.getItem('edit-link-intro')) {
        localStorage.setItem('edit-link-intro', 'true')
        setTimeout(function() {
            link.className="cs-edit-page-link hover";
        }, 2000);
        setTimeout(function() {
            link.className="cs-edit-page-link";
        }, 6000);
    }
})();