
document.querySelectorAll('.link-list.link-list-horizontal').forEach(a => {
    horizontalList(a as HTMLElement);
});


function horizontalList(element: HTMLElement) {
    var containerElement = element.querySelector('.link-list-container') as HTMLElement;
    var scrollElement = element.querySelector('.link-list-scroll-container') as HTMLElement;
    var nextButton = element.querySelector('.carousel-control-next');
    var prevButton = element.querySelector('.carousel-control-prev');
    var itemWidth = getItemWidth();

    //console.log('Link list', element.offsetWidth, containerElement.offsetWidth);

    evaluateControls();

    nextButton.addEventListener('click', function () {
        scrollElement.scrollBy(itemWidth, 0);
    })

    prevButton.addEventListener('click', function () {
        scrollElement.scrollBy(-itemWidth, 0);
    })
    
    let timer;
    scrollElement.addEventListener('scroll', function () {
        console.log('scroll');
        clearTimeout(timer);
        timer = setTimeout(evaluateControls, 100);
    })

    function evaluateControls() {
        var innerWidth = containerElement.scrollWidth;
        var outerWidth = scrollElement.offsetWidth;
        var scrollLeft = scrollElement.scrollLeft;
        console.log(innerWidth, outerWidth, scrollLeft);

        //if(innerWidth > outerWidth) {
            element.classList.toggle("can-prev", scrollLeft > 0);
            element.classList.toggle("can-next", (innerWidth - scrollLeft) > outerWidth);
        //}
    }

    function getItemWidth() {
        var items = element.querySelectorAll('.link-list-item');
        if(items.length > 1) {
            return (items[1] as HTMLElement).offsetLeft;
        } else {
            return 0;
        }
    }
}