import * as Popper from '@popperjs/core'

var favourites = (function() {

    var favbutton = document.querySelector('#favourites-button'),
        addbutton = document.querySelector('#favourites-add-button'),
        favpanel: HTMLElement = document.querySelector('#favourites-panel'),
        favlistpanel = document.querySelector('#favourites-list'),
        favclosebutton = document.querySelector('#favourites-close-button'),
        key = 'favourites',
        favourites = getfavourites(),
        popper,
        shown = false;

    popper = Popper.createPopper(favbutton, favpanel, {placement: 'bottom', modifiers: [{name: 'offset', options: {offset: [ 0, 10 ]}}]});

    favbutton.addEventListener('click', showFavouritesPanel);
    favclosebutton.addEventListener('click', showFavouritesPanel);
    addbutton.addEventListener('click', addCurrentPage);

    renderList();

    function addCurrentPage() {
        favourites.push({
            href: document.location.href,
            name: document.title
        });
        renderList();
        saveFavourites();
    }

    function favlistitem(name, href, index) {
        var div = document.createElement('div'),
            a = document.createElement('a'),
            btn = document.createElement('button');
        btn.innerHTML = '<span class="bi bi-x"></span>';
        btn.type = 'button';
        btn.title = 'Remove from list';
        btn.addEventListener('click', removeItem);
        a.text = name;
        a.href = href;
        div.className = "favourite-list-item";
        div.setAttribute('data-index', index);
        div.append(btn);
        div.append(a);
        return div;
    }

    function getfavourites() {
        var s = localStorage.getItem(key);
        if(s) {
            return JSON.parse(s);
        } else {
            return [];
        }
    }

    function removeItem(e) {
        e.stopPropagation();
        var p = e.target;
        while(p.className != 'favourite-list-item') {
            p = p.parentNode;
        }
        if(p) {
            var index = parseInt(p.attributes['data-index']);
            favourites.splice(index, 1);
            saveFavourites();
            renderList();
        }
    }

    function renderList() {
        favlistpanel.innerHTML = '';
        favourites.forEach(function(f, i) {
            favlistpanel.append(favlistitem(f.name, f.href, i));
        });
    }

    function saveFavourites() {
        if(favourites && favourites.length > 0) {
            localStorage.setItem(key, JSON.stringify(favourites));
        } else {
            localStorage.removeItem(key);
        }
    }

    function showFavouritesPanel() {
        if(!shown) {
            popper.update();
            favpanel.className="fade";
            favpanel.className="fade in";
        } else {
            favpanel.className = "fade";
            setTimeout(function() {
                favpanel.className = "";
            }, 100);
        }
        shown = !shown;
    }

    return {
        favlistpanel: favlistpanel
    };
})();