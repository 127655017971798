import './nzaptools/index'

document.querySelectorAll('[data-app]').forEach(async function(element) {
    var app = element.getAttribute('data-app');
    var {default: fn} = await getApp(app);

    if(fn) {
        fn(element);
    }
});
 

async function getApp(name: string): Promise<{default(element: Element): void}> {
    if(name == 'icpr') {
        return await import(`./icpr/app`);
    } else if (name == 'ivaproductionsites') {
        return await import(`./ivaproductionsites/app`);
    } else if (name == 'labourad') {
        return await import(`./labourad/app`);
    } else if (name == 'maps') {
        return await import(`./maps/app`);
    } else if (name == 'marketauditaustralia') {
        return await import(`./marketauditaustralia/app`);
    } else if (name == 'marketauditcm') {
        return await import(`./marketauditcm/app`);
    } else if (name == 'marketauditrussia') {
        return await import(`./marketauditrussia/app`);
    } else if (name == 'marketlog') {
        return await import(`./marketlog/app`);
    } else if (name == 'marketregister') {
        return await import(`./marketregister/app`);
    } else if (name == 'marketrequirements') {
        return await import(`./marketrequirements/app`);
    } else if (name == 'midge') {
        return await import(`./midge/app`);
    } else if (name == 'myob') {
        return await import(`./myob/app`);
    } else if (name == 'phi') {
        return await import(`./phi/app`);
    } else if (name == 'projects') {
        return await import(`./projects/app`);
    } else if (name == 'registrations') {
        return await import(`./registrations/app`);
    } else if (name == 'sitedocumentupload') {
        return await import(`./sitedocumentupload/app`);
    } else if (name == 'usda') {
        return await import(`./usda/app`);
    } 
}